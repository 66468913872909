import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';

import TopNav from '@appCore/components/navigation/public/TopNav';
import Footer from '@appCore/components/navigation/public/Footer';

import { useAccountUrlBuilder } from '@appPublic/routes/account/plans/urlBuilder';
import { useCommitmentsUrlBuilder } from '@appPublic/routes/commitments/urlBuilder';
import { useTeamsUrlBuilder } from '@appPublic/routes/teams/urlBuilder';
import { useEventsUrlBuilder } from '@appPublic/routes/events/urlBuilder';
import { useJobOpeningsUrlBuilder } from '@appPublic/routes/job-openings/urlBuilder';
import { useRecruitingNeedsUrlBuilder } from '@appPublic/routes/recruiting-needs/urlBuilder';

import { NavLinksContext } from '@appCore/components/navigation/public/utility';
import LayoutErrorBoundary from './LayoutErrorBoundary';

const contentHeightContext = React.createContext(null);

const useContentHeightContext = () => useContext(contentHeightContext);

const UNSTABLE_ContentHeightProvider = ({ children }) => {
    const [className, setClassName] = useState(null);
    return (
        <contentHeightContext.Provider value={{ className, setClassName }}>{children}</contentHeightContext.Provider>
    );
};

/**
 * This hook and (and its corresponding provider) were created to address poor CLS (content layout shift) scores
 * reported from Core Web Vitals.
 *
 * The problem:
 * - The loading pattern for various pages is such that a loading indicator (usually a skeleton) will be displayed,
 * followed by the actual content once loading is complete. When this happens, if there's enough variance between
 * the height of the loading indicator's content and that of the actual content, the footer will see its position
 * change enough to produce an undesirable CLS score
 *
 * The current solution:
 * - By dynamically setting the expected content height (i.e. the maximum height the content should have at any point
 * before, during, or after all content is loaded) for a given page, both the loading state and fully rendered state
 * should be essentially equivalent in height, so we can eliminate the resultant shift on the footer.
 */
export const UNSTABLE_useContentHeight = ({ className }) => {
    const { setClassName } = useContentHeightContext();
    useEffect(() => {
        setClassName(className);
        return () => setClassName(null);
    }, []);
};

const ContentContainer = ({ children }) => {
    const { className } = useContentHeightContext();
    return (
        <div className={cx('min-h-screen', className)}>
            <LayoutErrorBoundary>{children}</LayoutErrorBoundary>
        </div>
    );
};

const Layout = ({ children }) => {
    const accountUrlBuilder = useAccountUrlBuilder();
    const commitmentsUrlBuilder = useCommitmentsUrlBuilder();
    const teamsUrlBuilder = useTeamsUrlBuilder();
    const eventsUrlBuilder = useEventsUrlBuilder();
    const jobOpeningsUrlBuilder = useJobOpeningsUrlBuilder();
    const recruitingNeedsUrlBuilder = useRecruitingNeedsUrlBuilder();

    const linkProps = {
        accountPlansLinkProps: accountUrlBuilder.accountPlansAthletes().wiredLinkProps,
        commitmentsLinkProps: commitmentsUrlBuilder.commitmentsList().wiredLinkProps,
        teamsLinkProps: teamsUrlBuilder.teamsList().wiredLinkProps,
        eventsLinkProps: eventsUrlBuilder.eventsList().wiredLinkProps,
        jobOpeningsLinkProps: jobOpeningsUrlBuilder.jobOpeningsList().wiredLinkProps,
        recruitingNeedsLinkProps: recruitingNeedsUrlBuilder.recruitingNeedsList().wiredLinkProps
    };

    return (
        <NavLinksContext.Provider value={linkProps}>
            <TopNav />
            <UNSTABLE_ContentHeightProvider>
                <ContentContainer>{children}</ContentContainer>
            </UNSTABLE_ContentHeightProvider>
            <Footer />
        </NavLinksContext.Provider>
    );
};

export default Layout;
